var section_12 = document.querySelector('#section-12');

gsap.set("#f13-lightbulb", { transformOrigin: "center center" });
gsap.set("#f13-lightbulb-rays", { transformOrigin: "center center" });

// master

var t12 = gsap.timeline({
    scrollTrigger: {
        trigger: "#f13-circle",
        start: "top bottom",
        end: "center center",
        scrub: true,
        snap: {
            snapTo: [0.25, 1],
            duration: 1.6,
            delay: 0.4,
        }
    }
});

t12.addLabel("start");
t12.fromTo("#section-12 .intro-container", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "start");
t12.fromTo("#f13-element-group", { x: -1440 }, { x: 0, duration: 1, ease: "none" }, "start");
t12.fromTo(".f13-layer-1", { x: -320 }, { x: 0, duration: 1, ease: "none" }, "start");
t12.fromTo(".f13-layer-2", { x: -640 }, { x: 0, duration: 1, ease: "none" }, "start");
t12.fromTo(".f13-layer-3", { x: -960 }, { x: 0, duration: 1, ease: "none" }, "start");
t12.fromTo(".f13-layer-4", { x: -1280 }, { x: 0, duration: 1, ease: "none" }, "start");
t12.fromTo("#f13-lightbulb", { y: 240, scale: 0.25 }, { y: 0, scale: 1, duration: 0.5, ease: "none" }, "start+=0.5");
t12.fromTo("#f13-lightbulb-rays", { scale: 0, opacity: 0 }, { scale: 1, opacity: 1, duration: 0.5, ease: Power4.easeIn }, "start+=0.5");