function update_hash() {
    var top = window.pageYOffset || document.documentElement.scrollTop;
    if (top <= 0) {
        if (history.replaceState) {
            history.replaceState(null, null, location.pathname);
        }
        if (document.querySelector('.link-active') !== null) {
            document.querySelector('.link-active').classList.remove('link-active');
        }
    } else {
        var anchors = document.querySelectorAll('.anchor');
        var threshold = window.innerHeight / 3;
        for (var i = 0; i < anchors.length; i++) {
            var id = anchors[i].id;
            var rect = anchors[i].getBoundingClientRect().y;
            var page_data = {
                id: id,
                rect: rect
            };
            if (page_data.rect > (threshold * -1) && page_data.rect < threshold) {
                // remove active link
                if (document.querySelector('.link-active') !== null) {
                    document.querySelector('.link-active').classList.remove('link-active');
                }
                // add active link
                var active_link = document.querySelector('.'+ page_data.id);
                if (active_link) {
                    active_link.classList.add('link-active');
                }
                if (page_data.id !== location.hash.substr(1)) {
                    // update hash
                    if (history.replaceState) {
                        history.replaceState(null, null, '#'+ page_data.id);
                    } else {
                        location.hash = '#'+ page_data.id;
                    }
                } else {
                    return;
                }
            }
        }     
    }
}
window.addEventListener('scroll', update_hash);

document.querySelectorAll('.link').forEach(item => {
    item.addEventListener('click', function() {
        if (document.querySelector('.link-active') !== null) {
            document.querySelector('.link-active').classList.remove('link-active');
        }
        this.classList.add('link-active');
    });
});