var section_06 = document.querySelector('#section-06');

gsap.set("#f11-lightbulb", { transformOrigin: "center center" });
gsap.set("#f11-lightbulb-rays", { transformOrigin: "center center" });
gsap.set("#f11-leaf-1", { transformOrigin: "50% 100%" });
gsap.set("#f11-leaf-2", { transformOrigin: "100% 100%" });
gsap.set("#f11-leaf-3", { transformOrigin: "100% 100%" });

// master

var t6 = gsap.timeline({
    scrollTrigger: {
        trigger: "#f11-circle-group",
        start: "top bottom",
        end: "center center",
        scrub: true,
        snap: {
            snapTo: [0.25, 1],
            duration: 1.6,
            delay: 0.4,
        }
    }
});

t6.addLabel("start");
t6.fromTo("#section-06 .intro-container", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "start");
t6.fromTo("#f11-circle-group", { x: 960 }, { x: 0, duration: 1, ease: "none" }, "start");
t6.fromTo(".f11-layer-1", { x: 480 }, { x: 0, duration: 1, ease: "none" }, "start");
t6.fromTo(".f11-layer-2", { x: 960 }, { x: 0, duration: 1, ease: "none" }, "start");
t6.fromTo(".f11-layer-3", { x: 1440 }, { x: 0, duration: 1, ease: "none" }, "start");
t6.fromTo("#f11-leaf-1", { scale: 0 }, { scale: 1, duration: 1, ease: Power4.easeIn }, "start");
t6.fromTo("#f11-leaf-2", { scale: 0, rotation: 45 }, { scale: 1, rotation: 0, duration: 1, ease: Power4.easeIn }, "start");
t6.fromTo("#f11-leaf-3", { scale: 0, rotation: 60 }, { scale: 1, rotation: 0, duration: 1, ease: Power4.easeIn }, "start");
t6.fromTo("#f11-lightbulb", { y: 240, scale: 0.25 }, { y: 0, scale: 1, duration: 0.5, ease: "none" }, "start+=0.5");
t6.fromTo("#f11-lightbulb-rays", { scale: 0, opacity: 0 }, { scale: 1, opacity: 1, duration: 0.5, ease: Power4.easeIn }, "start+=0.5");