var section_16 = document.querySelector('#section-16');

gsap.set("#f21-shield", { transformOrigin: "center center" });

// master

var t16 = gsap.timeline({
    scrollTrigger: {
        trigger: "#section-16",
        start: "top center",
        end: "bottom bottom",
        scrub: true,
        snap: {
            snapTo: [1],
            duration: 1.6,
            delay: 0.4,
        }
    }
});

t16.addLabel("start");
t16.fromTo("#f21-graphic-group", { x: -1920 }, { x: 0, duration: 1.8, ease: "none" }, "start");
t16.fromTo(".f21-layer-1", { x: -480 }, { x: 0, duration: 1.8, ease: "none" }, "start");
t16.fromTo(".f21-layer-2", { x: -960 }, { x: 0, duration: 1.8, ease: "none" }, "start");
t16.fromTo(".f21-layer-3", { x: -1440 }, { x: 0, duration: 1.8, ease: "none" }, "start");
t16.fromTo(".f21-layer-4", { x: -1920 }, { x: 0, duration: 1.8, ease: "none" }, "start");
t16.fromTo(".f21-layer-5", { x: -2400 }, { x: 0, duration: 1.8, ease: "none" }, "start");
t16.fromTo("#f21-shield", { scaleX: 0, opacity: 0 }, { scaleX: 1, opacity: 1, duration: 1.8, ease: "none" }, "start");
t16.fromTo("#f21-lakehead", { x: 120 }, { x: 0, duration: 1.8, ease: "none" }, "start");
t16.fromTo("#f21-lakehead path", { opacity: 0 }, { opacity: 1, stagger: 0.1, duration: 1, ease: "none" }, "start");
t16.fromTo("#f21-university", { x: 66.66667, y: 33.33333, opacity: 0 }, { x: 0, y: 0, opacity: 1, duration: 1, ease: "none" }, "start+=0.8");