var section_15 = document.querySelector('#section-15');

// start

var t15 = gsap.timeline({
    scrollTrigger: {
        id: 't15',
        trigger: "#section-15-trigger",
        start: "top bottom",
        end: "top top",
        scrub: true,
        snap: {
            snapTo: [0.33333, 1],
            duration: 1.6,
            delay: 0.4,
        }
    }
});

t15.addLabel("start");
t15.fromTo("#section-15 .intro-container", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "start");
t15.fromTo("#f14-graphic", { y: 320 }, { y: 0, duration: 1, ease: "none" }, "start");
t15.fromTo(".f14-layer-1", { y: 320 }, { y: 0, duration: 1, ease: "none" }, "start");
t15.fromTo(".f14-layer-2", { y: 640 }, { y: 0, duration: 1, ease: "none" }, "start");
t15.fromTo(".f14-layer-3", { y: 960 }, { y: 0, duration: 1, ease: "none" }, "start");

// modules

function t15_a() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.fromTo("#section-15 .intro-container", { x: 0 }, { x: "-200vw", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f14", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f14-graphic", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f14-layer-1", { x: 0 }, { x: -480, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f14-layer-2", { x: 0 }, { x: -960, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f14-layer-3", { x: 0 }, { x: -1440, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f14-blob-front", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f15", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f15-graphic", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f15-layer-1", { x: 480 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f15-layer-2", { x: 960 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f15-layer-3", { x: 1440 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f15-stat", { x: "200vw" }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f16", { x: 3840 }, { x: 1920, duration: 1, ease: "none" }, "start");
    return tl;
}

function t15_b() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.fromTo("#f14", { x: -1920 }, { x: -3840, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f15", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f15-graphic", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f15-layer-1", { x: 0 }, { x: -480, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f15-layer-2", { x: 0 }, { x: -960, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f15-layer-3", { x: 0 }, { x: -1440, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f15-stat", { x: 0 }, { x: "-200vw", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f16", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f16-graphic", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f16-layer-1", { x: 480 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f16-layer-2", { x: 960 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f16-layer-3", { x: 1440 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f16-stat", { x: "200vw" }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f17", { x: 3840 }, { x: 1920, duration: 1, ease: "none" }, "start");
    return tl;
}

function t15_c() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.fromTo("#f15", { x: -1920 }, { x: -3840, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f16", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f16-graphic", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f16-layer-1", { x: 0 }, { x: -480, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f16-layer-2", { x: 0 }, { x: -960, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f16-layer-3", { x: 0 }, { x: -1440, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f16-stat", { x: 0 }, { x: "-200vw", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f17", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f17-graphic", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f17-layer-1", { x: 480 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f17-layer-2", { x: 960 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f17-layer-3", { x: 1440 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f17-stat", { x: "200vw" }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f18", { x: 3840 }, { x: 1920, duration: 1, ease: "none" }, "start");
    return tl;
}

function t15_d() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.fromTo("#f16", { x: -1920 }, { x: -3840, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f17", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f17-graphic", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f17-layer-1", { x: 0 }, { x: -480, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f17-layer-2", { x: 0 }, { x: -960, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f17-layer-3", { x: 0 }, { x: -1440, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f17-stat", { x: 0 }, { x: "-200vw", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f18", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f18-graphic", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f18-layer-1", { x: 480 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f18-layer-2", { x: 960 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f18-layer-3", { x: 1440 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f18-stat", { x: "200vw" }, { x: 0, duration: 1, ease: "none" }, "start");
    return tl;
}

function t15_e() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.fromTo("#f17", { x: -1920 }, { x: -3840, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f18", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f18-graphic", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f18-layer-1", { x: 0 }, { x: -480, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f18-layer-2", { x: 0 }, { x: -960, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f18-layer-3", { x: 0 }, { x: -1440, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f18-stat", { x: 0 }, { x: "-200vw", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f19", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f19-graphic", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f19-layer-1", { x: 480 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f19-layer-2", { x: 960 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f19-layer-3", { x: 1440 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f19-layer-4", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f19-stat", { x: "200vw" }, { x: 0, duration: 1, ease: "none" }, "start");
    return tl;
}

function t15_f() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.fromTo("#f18", { x: -1920 }, { x: -3840, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f19", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f19-graphic", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f19-layer-1", { x: 0 }, { x: -480, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f19-layer-2", { x: 0 }, { x: -960, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f19-layer-3", { x: 0 }, { x: -1440, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f19-layer-4", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f19-stat", { x: 0 }, { x: "-200vw", duration: 1, ease: "none" }, "start");
    tl.fromTo("#the", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#the-stat-graphic", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".the-stat-layer-1", { x: 480 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".the-stat-layer-2", { x: 960 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".the-stat-layer-3", { x: 1440 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#the-stat", { x: "200vw" }, { x: 0, duration: 1, ease: "none" }, "start");
    return tl;
}

function t15_g() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.fromTo("#f19", { x: -1920 }, { x: -3840, duration: 1, ease: "none" }, "start");
    tl.fromTo("#the", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo("#the-stat-graphic", { x: 0 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo(".the-stat-layer-1", { x: 0 }, { x: -480, duration: 1, ease: "none" }, "start");
    tl.fromTo(".the-stat-layer-2", { x: 0 }, { x: -960, duration: 1, ease: "none" }, "start");
    tl.fromTo(".the-stat-layer-3", { x: 0 }, { x: -1440, duration: 1, ease: "none" }, "start");
    tl.fromTo("#the-stat", { x: 0 }, { x: "-200vw", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f20", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f20-graphic", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f20-layer-1", { x: 480 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f20-layer-2", { x: 960 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f20-layer-3", { x: 1440 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f20-layer-4", { x: 1920 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f20-layer-5", { x: 2400 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f20-stat", { x: "200vw" }, { x: 0, duration: 1, ease: "none" }, "start");
    return tl;
}

// master

let stats = gsap.utils.toArray(".stat");

var t15_stats = gsap.timeline({
    scrollTrigger: {
        id: 'stats',
        trigger: "#section-15",
        pin: true,
        start: "center center",
        end: () => "+=" + ((section_15.offsetWidth * 0.75) * stats.length),
        scrub: true,
        snap: {
            snapTo: 1 / (stats.length),
            duration: 1.6,
            delay: 0.4,
        }
    }
});
t15_stats.add(t15_a());
t15_stats.add(t15_b());
t15_stats.add(t15_c());
t15_stats.add(t15_d());
t15_stats.add(t15_e());
t15_stats.add(t15_f());
t15_stats.add(t15_g());