gsap.set("#f7-woman-arm-left", { transformOrigin: "100% 100%" });
gsap.set("#f7-woman-hand-left", { rotation: 5 });
gsap.set("#f7-woman-arm-right", { transformOrigin: "0% 100%" });
gsap.set("#f7-woman-leg-left-after", { transformOrigin: "10% 10%" });
gsap.set("#f7-woman-leg-right-after", { transformOrigin: "10% 90%" });
gsap.set("#f7-couple-arm-left", { transformOrigin: "90% 10%" });
gsap.set("#f7-woman-left-forearm", { transformOrigin: "90% 100%" });
gsap.set("#f7-couple-arm-right", { transformOrigin: "5% 90%" });

// master

var t2click = gsap.timeline();

t2click.addLabel("start");
t2click.fromTo("#f7-woman", { y: 80 }, { y: 0, duration: 1, ease: Power2.easeOut }, "start");
t2click.from("#f7-woman-gown-after", { morphSVG: '#f7-woman-gown-before', duration: 1, ease: Power2.easeOut }, "start");
t2click.fromTo("#f7-woman-leg-left-after", { x: 40, y: 40, rotation: 30}, { x: 0, y: 0, rotation: 0, duration: 1, ease: Power2.easeOut }, "start");
t2click.fromTo("#f7-woman-leg-right-after", { rotation: 135}, { rotation: 0, duration: 1, ease: Power2.easeOut }, "start");
t2click.fromTo("#f7-woman-arm-left", { rotation: -30 }, { rotation: 0, duration: 1, ease: Power2.easeIn }, "start");
t2click.fromTo("#f7-woman-diploma", { x: -130, y: 90 }, { x: 0, y: 0, duration: 1, ease: Power2.easeOut }, "start+=1");
t2click.fromTo("#f7-woman-arm-right", { rotation: 30 }, { rotation: 0, duration: 1, ease: Power2.easeIn }, "start");
t2click.fromTo("#f7-woman-cap", { x: 90, y: 120 }, { x: 0, y: 0, duration: 1, ease: Power1.easeOut }, "start+=1");
t2click.fromTo("#f7-couple-arm-left", { rotation: -15}, { rotation: 0, duration: 1, ease: Power2.easeOut }, "start");
t2click.fromTo("#f7-woman-left-forearm", { rotation: -45}, { rotation: 0, duration: 1, ease: Power2.easeOut }, "start");
t2click.fromTo("#f7-couple-arm-right", { rotation: 15}, { rotation: 0, duration: 1, ease: Power2.easeOut }, "start");
t2click.fromTo(".f7-layer-1", { y: 80 }, { y: 0, duration: 2, ease: Power2.easeOut }, "start");
t2click.fromTo(".f7-layer-2", { y: 160 }, { y: 0, duration: 2, ease: Power2.easeOut}, "start");
t2click.fromTo(".f7-layer-3", { y: 240 }, { y: 0, duration: 2, ease: Power2.easeOut }, "start");
t2click.pause(0);