var section_14 = document.querySelector('#section-14');

gsap.set("#section-14 .profile-image-container", { transformOrigin: "center center" });
gsap.set("#section-14 .profile-mask-path", { transformOrigin: "center center" });

// master

var t14 = gsap.timeline({
    scrollTrigger: {
        id: 't14a',
        trigger: "#section-14 .profile-intro-container",
        start: "top bottom",
        end: "center center",
        scrub: true,
        snap: {
            snapTo: [0.25, 1],
            duration: 1.6,
            delay: 0.4,
        }
    }
});

// t14

var h1_split = new SplitText("#section-14 .profile-intro-content h1", {type: "chars,words,lines", position: "relative"});

t14.addLabel("start");
t14.addLabel("morph", "start+=0.5");
t14.addLabel("text", "morph+=0.5");
t14.addLabel("text-p", "text+=0.5");
t14.fromTo("#section-14 .blob-mask-path", { x: -2560 }, { x: 960, duration: 2, ease: "none" }, "start");
t14.from("#section-14 .blob-mask-path", { morphSVG: '#section-14 .blob-mask-morph', duration: 2, ease: "none" }, "morph");
t14.from("#section-14 .blob-1", { morphSVG: '#section-14 .blob-1-morph', duration: 2, ease: "none" }, "morph");
t14.from("#section-14 .blob-3", { morphSVG: '#section-14 .blob-3-morph', duration: 2, ease: "none" }, "morph");
t14.fromTo("#section-14 .blob-2", { x: 1280 }, { x: 0, duration: 2, ease: "none" }, "morph");
t14.fromTo("#section-14 .profile-image-container", { x: "-75vw", scale: 0.5 }, { x: 0, scale: 1, duration: 2, ease: "none" }, "morph");
t14.from("#section-14 .profile-mask-path", { morphSVG: '#section-14 .profile-mask-morph', duration: 2, ease: "none" }, "morph");
t14.fromTo("#section-14 .profile-mask-path", { scale: 0.5 }, { scale: 1, duration: 2, ease: "none" }, "morph");
t14.fromTo("#section-14 .profile-line-path", { drawSVG: "100% 100%" }, { drawSVG: "0% 100%", duration: 1, ease: "none" }, "text-p");
t14.fromTo("#section-14 .profile-intro-content h1", { x: 100 }, { x: 0, duration: 1.5, ease: "none" }, "text");
t14.fromTo(h1_split.chars, { opacity: 0 }, { opacity: 1, stagger: 0.1, duration: 1, ease: "none" }, "text");
t14.fromTo("#section-14 .profile-tag-container", { x: -100, opacity: 0 }, { x: 0, opacity: 1, duration: 1, ease: "none" }, "text-p");
t14.fromTo("#section-14 .profile-intro-content p", { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1, ease: "none" }, "text-p");

// t14b

var t14b = gsap.timeline({
    scrollTrigger: {
        id: 't14b',
        trigger: "#section-14 .profile-intro-container",
        pin: true,
        start: "center center",
        end:  "bottom center",
        scrub: true
    }
});

t14b.addLabel("start");
t14b.addLabel("content", "start+=1");
t14b.fromTo("#section-14 .blob-mask-path", { x: 960 }, { x: 3200, duration: 2, ease: "none" }, "start");
t14b.fromTo("#section-14 .blob-1", { x: 0 }, { x: 3200, duration: 2, ease: "none" }, "start");
t14b.fromTo("#section-14 .blob-2", { x: 0, }, { x: 3200, duration: 2, ease: "none" }, "start");
t14b.fromTo("#section-14 .blob-3", { x: 0 }, { x: 3200, duration: 2, ease: "none" }, "start");
t14b.fromTo("#section-14 .profile-background", { y: 0 }, { y: "-50vh", duration: 2, ease: "none" }, "start");
t14b.fromTo("#section-14 .profile-intro", { x: 0, y: 0 }, { x: "100vw", y: "-50vh", duration: 2, ease: "none" }, "start");
t14b.fromTo("#section-14 .profile-intro", { opacity: 1 }, { opacity: 0, duration: 1, ease: "none" }, "start");
t14b.fromTo("#section-14 .profile-banner", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "content");
t14b.fromTo("#section-14 .profile-banner-image", { y: "-10vh" }, { y: 0, duration: 1, ease: "none" }, "content");
t14b.fromTo("#section-14 .profile-article-container", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "content");