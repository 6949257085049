var section_03 = document.querySelector('#section-03');

// master

var t3 = gsap.timeline({
    scrollTrigger: {
        trigger: "#section-03",
        start: "top bottom",
        end: "bottom top",
        scrub: true
    }
});

t3.addLabel("start");
t3.addLabel("video", "start+=0.25");
t3.fromTo("#f8-blob-grey-top, #f8-blob-grey-btm, #f8-building", { y: 270 }, { y: -270, duration: 1, ease: "none" }, "start");
t3.fromTo("#f8-blob-grey-top, #f8-blob-grey-btm, #f8-building", { y: 270 }, { y: -270, duration: 1, ease: "none" }, "start");
t3.fromTo("#f8-blob-pink", { y: 540 }, { y: -540, duration: 1, ease: "none" }, "start");
t3.fromTo("#section-03 .video-container", { opacity: 0 }, { opacity: 1, duration: 0.2, ease: "none" }, "video");