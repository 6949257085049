// factoids

var factoids_glide = new Glide('.factoids', {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    animationDuration: 800,
    gap: 0
});

factoids_glide.mount();

// research

var research_glide = new Glide('.research', {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    animationDuration: 800,
    gap: 0
});

function research_pin_update() {
    var researchers = document.querySelectorAll('.research .glide__slide:not(.glide__slide--clone)');
    var researcher = researchers.item(research_glide.index);
    var blaze = '#ffc20e';
    var cobalt = '#00427a';
    gsap.to(".f12-pin", { fill: cobalt, duration: 0.4, ease: Power1.easeIn });
    if (researcher) {
        var research_content = researcher.querySelector('.research-content');
        if (research_content) {
            var pin_data = research_content.dataset.pins;
            if (pin_data) { 
                var pins = pin_data.split(',');
                for (var i = 0; i < pins.length; i++) {
                    var pin = pins[i];
                    gsap.to(pin, { fill: blaze, duration: 0.4, ease: Power1.easeIn });
                }
            }
        }
    }
}

function research_resize() {
    setTimeout(function() {
        research_glide.destroy();
        research_glide = new Glide('.research', {
            type: 'carousel',
            startAt: 0,
            perView: 1,
            animationDuration: 800,
            gap: 0
        });
        research_glide.on(['build.after', 'run'], research_pin_update);
        research_glide.on(['resize'], research_resize);
        research_glide.mount();
    }, 500);
}

research_glide.on(['build.after', 'run'], research_pin_update);
research_glide.on(['resize'], research_resize);
research_glide.mount();