var section_the = document.querySelector('#section-the');
var section_svg_the2 = document.querySelector('#section-the .svg-the2');
var section_svg_the2_svg = section_svg_the2.querySelector('svg');
var section_svg_the3 = document.querySelector('#section-the .svg-the3');
var section_svg_the3_svg = section_svg_the3.querySelector('svg');

// master

var tthe = gsap.timeline({
    scrollTrigger: {
        trigger: "#the1-circle",
        start: "top bottom",
        end: "center center",
        scrub: true,
    }
});

tthe.addLabel("start");
tthe.fromTo("#section-the .intro-container", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "start");
tthe.fromTo("#the1-graphic", { x: -1440 }, { x: 0, duration: 1, ease: "none" }, "start");
tthe.fromTo(".the1-layer-1", { x: -480 }, { x: 0, duration: 1, ease: "none" }, "start");
tthe.fromTo(".the1-layer-2", { x: -960 }, { x: 0, duration: 1, ease: "none" }, "start");
tthe.fromTo(".the1-layer-3", { x: -1440 }, { x: 0, duration: 1, ease: "none" }, "start");

var tthe2 = gsap.timeline({
    scrollTrigger: {
        trigger: "#section-the .svg-the2",
        start: "top bottom",
        end: "bottom top",
        scrub: true
    }
});

tthe2.addLabel("start");
tthe2.fromTo("#section-the .svg-the2 svg", { y: () => ((section_svg_the2.offsetHeight / 2) - (section_svg_the2_svg.getBoundingClientRect().height / 2)) + (window.innerHeight / 4) }, { y: ((section_svg_the2.offsetHeight / 2) - (section_svg_the2_svg.getBoundingClientRect().height / 2)) - (window.innerHeight / 4), duration: 1, ease: "none" }, "start");

var tthe3 = gsap.timeline({
    scrollTrigger: {
        trigger: "#section-the .svg-the3",
        start: "top bottom",
        end: "bottom top",
        scrub: true
    }
});

tthe3.addLabel("start");
tthe3.fromTo("#section-the .svg-the3 svg", { y: () => ((section_svg_the3.offsetHeight / 2) - (section_svg_the3_svg.getBoundingClientRect().height / 2)) + (window.innerHeight / 4) }, { y: ((section_svg_the3.offsetHeight / 2) - (section_svg_the3_svg.getBoundingClientRect().height / 2)) - (window.innerHeight / 4), duration: 1, ease: "none" }, "start");