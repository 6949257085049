var section_01 = document.querySelector('#section-01');

gsap.set("#f1-shield", { transformOrigin: "center center" });
gsap.set("#f1-home-group", { transformOrigin: "center center" });
gsap.set("#f1-reader", { transformOrigin: "center center" });
gsap.set("#f2-reader_1_", { transformOrigin: "center center", opacity: 0 });
gsap.set("#f2-leaves", { transformOrigin: "center bottom" });
gsap.set("#f2-leaves > g, #f2-leaves > path", { transformOrigin: "center bottom" });
gsap.set("#f3-blob-embrace", { transformOrigin: "center bottom" });
gsap.set("#f3-blob-hope", { transformOrigin: "left 280px" });
gsap.set("#f4", { transformOrigin: "center center" });
gsap.set("#f4-arm-left", { transformOrigin: "88% 2%" });
gsap.set("#f4-arm-right", { transformOrigin: "12% 2%" });
gsap.set("#f4-logo-sun", { transformOrigin: "center 65%" });
gsap.set("#f5-shield", { transformOrigin: "center center" });
gsap.set("#f5-shield-sun", { transformOrigin: "center 75%", opacity: 0 });
gsap.set(".f5-shield-book", { transformOrigin: "center center" });

var t1start = gsap.timeline({
    onStart: function() { document.body.classList.add('loaded'); },
    delay: 1
});

t1start.addLabel("start");
t1start.fromTo("#f1-shield", { scaleX: 0, opacity: 0 }, { scaleX: 1, opacity: 1, duration: 1.8, ease: Power4.easeOut }, "start");
t1start.fromTo("#f1-lakehead", { x: 120 }, { x: 0, duration: 1.8, ease: Power4.easeOut }, "start");
t1start.fromTo("#f1-lakehead path", { opacity: 0 }, { opacity: 1, stagger: 0.1, duration: 1, ease: Power4.easeOut }, "start");
t1start.fromTo("#f1-university", { y: 33.33333, opacity: 0 }, { y: 0, opacity: 1, duration: 1, ease: Power4.easeOut }, "start+=0.8");
t1start.fromTo("#f1-home-group", { x: "50%", opacity: 0 }, { x: 0, opacity: 1, duration: 2, ease: Power4.easeOut }, "start");
t1start.fromTo("#f1-leaf-left, #f1-leaf-top", { x: 600 }, { x: 0, duration: 2, ease: Power4.easeOut }, "start");
t1start.fromTo("#f1-athlete, #f1-trophy", { x: 840 }, { x: -0, duration: 2, ease: Power4.easeOut }, "start");
t1start.fromTo("#f1-reader, #f1-researcher, #f1-platform, #f1-beaker, #f1-circle-group", { x: 1080 }, { x: 0, duration: 2, ease: Power4.easeOut }, "start");
t1start.fromTo("#f1-technician, #f1-ruler, #f1-book-btm, #f1-book-top", { x: 1320 }, { x: 0, duration: 2, ease: Power4.easeOut }, "start");
t1start.play();

// modules

function t1_a() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.fromTo("#f1-logo", { opacity: 1 }, { opacity: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f1", { x: 0 }, { x: -960, duration: 1, ease: "none" }, "start");
    return tl;
}

function t1_b() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.addLabel("report", "+=0.75");
    tl.set("#f1-reader .inner", { opacity: 0 });
    tl.set("#f2-reader_1_", { opacity: 1 });
    tl.to("#f1-blob_1_", { morphSVG: "#f2-blob", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f1-blob_1_", { x: 0 }, { x: 960, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f2-reader_1_", { x: -319.5, y: -319, scale: 0.64886126 }, { x: 0, y: 0, scale: 1, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f1-leaf-left .inner, #f1-leaf-top .inner", { x: 0 }, { x: -1440, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f1-athlete .inner, #f1-trophy .inner", { x: 0 }, { x: -1680, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f1-researcher .inner, #f1-platform .inner, #f1-beaker .inner, #f1-circle-group .inner", { x: 0 }, { x: -2160, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f1-technician .inner, #f1-ruler .inner, #f1-book-btm .inner, #f1-book-top .inner", { x: 0 }, { x: -2640, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f2-leaves", { x: -320, y: -480, scale: 0 }, { x: 0, y: 0, scale: 1, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f2-rotate-cw", { x: -120, y: -200, scale: 0, rotation: -90 }, { x: 0, y: 0, scale: 1, rotation: "0deg", stagger: 0.1, duration: 1, ease: "none" }, "start");
    tl.fromTo(".f2-rotate-ccw", { x: 120, y: -200, scale: 0, rotation: 90 }, { x: 0, y: 0, scale: 1, rotation: "0deg", stagger: 0.1, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f2-2019 path", { y: 120, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5, ease: "none" }, "report");
    tl.fromTo("#f2-2020 path", { y: 120, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5, ease: "none" }, "report");
    tl.fromTo("#f2-annual", { y: -60, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5, ease: "none" }, "report");
    tl.fromTo("#f2-report", { y: -60, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5, ease: "none" }, "report");
    tl.fromTo("#f2-magic-left path, #f2-magic-left circle", { y: -120, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.01, duration: 1, ease: Power4.easeOut }, "report");
    tl.fromTo("#f2-magic-center path, #f2-magic-center circle", { y: -120, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.01, duration: 1, ease: Power4.easeOut }, "report");
    tl.fromTo("#f2-magic-right path, #f2-magic-right circle", { y: -120, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.01, duration: 1, ease: Power4.easeOut }, "report");
    tl.fromTo("#f2-magic-left", { y: 0, opacity: 1 }, { y: 120, opacity: 0, duration: 1, ease: Power4.easeIn }, "report+=1");
    tl.fromTo("#f2-magic-center", { y: 0, opacity: 1 }, { y: 120, opacity: 0, duration: 1, ease: Power4.easeIn }, "report+=1");
    tl.fromTo("#f2-magic-right", { y: 0, opacity: 1 }, { y: 120, opacity: 0, duration: 1, ease: Power4.easeIn }, "report+=1");
    return tl;
}

function t1_c() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.addLabel("morph", "start+=1");
    tl.addLabel("hope", "morph+=3");
    tl.fromTo("#f2-2019 path", { x: 0, opacity: 1 }, { x: -960, opacity: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f2-2020 path", { x: 0, opacity: 1 }, { x: 960, opacity: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f2-annual", { x: 0, opacity: 1 }, { x: -960, opacity: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f2-report", { x: 0, opacity: 1 }, { x: 960, opacity: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f1-blob_1_", { x: 960, y: 0 }, { x: 454, y: 84, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f2-reader_1_, #f2-leaves", { x: 0, y: 0 }, { x: -506, y: 84, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f3-magic path, #f3-magic circle", { y: -120, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.01, duration: 1, ease: Power4.easeOut }, "morph");
    tl.fromTo("#f3-magic", { y: 0, opacity: 1 }, { y: 120, opacity: 0, duration: 1, ease: Power4.easeIn }, "morph+=1");
    tl.fromTo("#f3-blob-embrace", { x: -180, y: -40, scale: 0 }, { x: 0, y: 0, scale: 1, duration: 1, ease: "none" }, "morph");
    tl.fromTo("#f3-embrace path", { opacity: 0 }, { opacity: 1, stagger: 0.1, duration: 1, ease: Power4.easeOut }, "morph");
    tl.fromTo("#f3-blob-sm path", { scale: 0 }, { scale: 1, stagger: 0.25, duration: 0.5, ease: "none" }, "morph+=1");
    tl.fromTo("#f3-blob-hope", { scale: 0 }, { scale: 1, duration: 1, ease: "none" }, "morph+=2");
    tl.fromTo("#f4-hope path", { opacity: 0 }, { opacity: 1, stagger: 0.1, duration: 1, ease: Power4.easeOut }, "hope");
    tl.fromTo("#f4-arm-left", { y: 10, rotation: -60 }, { y: 0, rotation: 0, duration: 1, ease: Power4.easeOut }, "hope");
    tl.fromTo("#f4-arm-right", { y: 10, rotation: 60 }, { y: 0, rotation: 0, duration: 1, ease: Power4.easeOut }, "hope");
    tl.fromTo("#f4-fog-1", { x: 120 }, { x: -120, duration: 6, ease: "none" }, "morph");
    tl.fromTo("#f4-fog-2", { x: -120 }, { x: 120, duration: 6, ease: "none" }, "morph");
    return tl;
}

function t1_d() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.addLabel("sunrise", "start+=1");
    tl.to("#f3-blob-hope", { morphSVG: '#f3-blob-hope-morph', duration: 1, ease: "none" }, "start");
    tl.fromTo("#f4-group", { x: 700 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f1-blob_1_", { x: 454 }, { x: -960, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f2-reader_1_, #f2-leaves", { x: -506 }, { x: -1920, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f3-blob-sm", { x: 0 }, { x: -1414, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f3-blob-embrace", { x: 0 }, { x: -1414, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f3-embrace", { x: 0 }, { x: -1414, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f4-cloud", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f4-cloud", { x: 0 }, { x: -120, duration: 2, ease: "none" }, "start");
    tl.fromTo("#f4-hope path", { x: -120, y: 300 }, { x: 0, y: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f4-logo_1_", { x: 100, opacity: 0 }, { x: 0, opacity: 1, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f4-ampersand", { x: 100, opacity: 0 }, { x: 0, opacity: 1, duration: 1, ease: Power4.easeOut }, "start+=0.9");
    tl.fromTo("#f4-resilience path", { x: 100, opacity: 0 }, { x: 0, opacity: 1, stagger: 0.1, duration: 1, ease: Power4.easeOut }, "start+=1");
    tl.fromTo(".f4-sunrise-out", { opacity: 1 }, { opacity: 0, duration: 1, ease: "none" }, "sunrise");
    tl.fromTo("#f4-stars", { y: 0 }, { y: -120, duration: 1, ease: "none" }, "sunrise");
    tl.fromTo("#f4-moon", { y: 0 }, { y: -240, duration: 1, ease: Power1.easeOut }, "sunrise");
    tl.fromTo("#f4-moonlight", { y: 0 }, { y: 60, duration: 1, ease: "none" }, "sunrise");
    tl.from(".f4-sunrise-in", { opacity: 0, duration: 1, ease: "none" }, "sunrise");
    tl.fromTo("#f4-sun-group", { y: 70 }, { y: 0, duration: 1, ease: "none" }, "sunrise");
    tl.fromTo("#f4-logo-sun", { scale: 0 }, { scale: 1, duration: 1, ease: "back.out(4)" }, "sunrise");
    tl.fromTo("#f4-sunrise-cloud_2_", { x: 120 }, { x: 0, duration: 2, ease: "none" }, "sunrise");
    tl.fromTo("#f4-sunrise-cloud_1_", { x: 360 }, { x: 0, duration: 2, ease: "none" }, "sunrise");
    tl.fromTo("#f4-sunrise-cloud", { x: -120 }, { x: 0, duration: 2, ease: "none" }, "sunrise");
    return tl;
}

function t1_e() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.addLabel("shield", "start+=1");
    tl.fromTo("#f3-blob-hope", { scale: 1 }, { scale: 2, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f4-hope-outlines", { opacity: 1 }, { opacity: 0, duration: 1, ease: Power4.easeOut }, "start");
    tl.fromTo("#f4", { x: 0 }, { x: -704.75, duration: 1, ease: "none" }, "start");
    tl.set("#f4-logo-sun", { opacity: 0 });
    tl.set("#f5-shield-sun", { opacity: 1 });
    tl.fromTo("#f4", { x: -704.75, y: 0, scale: 1 }, { x: -766, y: -198, scale: 1.087, duration: 1, ease: "none" }, "shield");
    tl.fromTo("#f5-shield", { y: 181.75, scale: 0.92 }, { y: 0, scale: 1, duration: 1, ease: "none" }, "shield");
    tl.fromTo(".f5-shield-fade", { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeIn }, "shield");
    tl.fromTo("#f5-shield-red-mask-path", { y: -1 }, { y: 1, duration: 1, ease: Power4.easeOut }, "shield");
    tl.fromTo("#f5-shield-sun", { y: 0, scale: 1 }, { y: 0, scale: 1.5, duration: 0.5, ease: Power1.easeOut }, "shield");
    tl.fromTo("#f5-shield-sun", { y: 0, scale: 1.5 }, { y: 0, scale: 1, duration: 0.5, ease: Power1.easeIn }, "shield+=0.5");
    tl.fromTo("#f5-shield-sun", { fill: "#FFFF63" }, { fill: "#ffc114", duration: 0.5, ease: Power1.easeIn }, "shield+=0.5");
    tl.set("#f3-blob-hope", { x: 715.25, y: 195.25, scale: 0.92, morphSVG: "#f5-shield-back-flat" }, "shield");
    tl.to("#f3-blob-hope", { morphSVG: '#f5-shield-back', duration: 1, ease: "none" }, "shield");
    tl.from("#f5-shield-red-mask-path", { morphSVG: '#f5-shield-red-flat', duration: 1, ease: "none" }, "shield");
    tl.from("#f5-shield-red", { morphSVG: '#f5-shield-red-flat', duration: 1, ease: "none" }, "shield");
    tl.from("#f5-shield-white-wave-1", { morphSVG: '#f5-shield-white-wave-1-flat', duration: 1, ease: "none" }, "shield");
    tl.from("#f5-shield-blue-wave-1", { morphSVG: '#f5-shield-blue-wave-1-flat', duration: 1, ease: "none" }, "shield");
    tl.from("#f5-shield-white-wave-2", { morphSVG: '#f5-shield-white-wave-2-flat', duration: 1, ease: "none" }, "shield");
    tl.from("#f5-shield-blue-wave-2", { morphSVG: '#f5-shield-blue-wave-2-flat', duration: 1, ease: "none" }, "shield");
    tl.from("#f5-shield-white-wave-3", { morphSVG: '#f5-shield-white-wave-3-flat', duration: 1, ease: "none" }, "shield");
    tl.from("#f5-shield-blue-wave-3", { morphSVG: '#f5-shield-blue-wave-3-flat', duration: 1, ease: "none" }, "shield");
    tl.fromTo(".f5-shield-book", { x: -360, y: -360, scale: 1.5 }, { x: 0, y: 0, scale: 1, duration: 1, ease: Power1.easeIn }, "shield");
    tl.fromTo("#f5-shield-book-white", { opacity: 0 }, { opacity: 1, duration: 0.5, ease: Power1.easeIn }, "shield+=0.5");
    tl.fromTo("#f5-shield-book-blue", { opacity: 0 }, { opacity: 1, duration: 0.5, ease: Power1.easeOut }, "shield+=0.5");
    tl.fromTo("#f4", { opacity: 1 }, { opacity: 0, duration: 0.1, ease: Power1.easeOut }, "shield+=1");
    tl.fromTo("#f5-shield-back", { opacity: 0 }, { opacity: 1, duration: 0.1, ease: Power1.easeOut }, "shield+=1");
    return tl;
}

function t1_f() {
    var tl = gsap.timeline();
    tl.addLabel("start");
    tl.set("#f4", { display: "none" }, "start");
    tl.fromTo("#f5-shield", { x: 0 }, { x: -530, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f5-shield-red", { fill: "#c52127" }, { fill: "#ffffff", duration: 1, ease: "none" }, "start");
    tl.set("#f5-shield-sun", { fill: "url(#f6-shield-sun-grad)" }, "start");
    tl.fromTo("#f6-shield-sun-grad-1", { stopColor: "#ffc114" }, { stopColor: "#27AAE1", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f6-shield-sun-grad-2", { stopColor: "#ffc114" }, { stopColor: "#00BDAA", duration: 1, ease: "none" }, "start");
    tl.set("#f5-shield-blue-wave-1", { fill: "url(#f6-shield-blue-wave-1-grad)" }, "start");
    tl.fromTo("#f6-shield-blue-wave-1-grad-1", { stopColor: "#0e4c87" }, { stopColor: "#27AAE1", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f6-shield-blue-wave-1-grad-2", { stopColor: "#0e4c87" }, { stopColor: "#00BDAA", duration: 1, ease: "none" }, "start");
    tl.set("#f5-shield-blue-wave-2", { fill: "url(#f6-shield-blue-wave-2-grad)" }, "start");
    tl.fromTo("#f6-shield-blue-wave-2-grad-1", { stopColor: "#0e4c87" }, { stopColor: "#27AAE1", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f6-shield-blue-wave-2-grad-2", { stopColor: "#0e4c87" }, { stopColor: "#00BDAA", duration: 1, ease: "none" }, "start");
    tl.set("#f5-shield-blue-wave-3", { fill: "url(#f6-shield-blue-wave-3-grad)" }, "start");
    tl.fromTo("#f6-shield-blue-wave-3-grad-1", { stopColor: "#0e4c87" }, { stopColor: "#27AAE1", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f6-shield-blue-wave-3-grad-2", { stopColor: "#0e4c87" }, { stopColor: "#00BDAA", duration: 1, ease: "none" }, "start");
    tl.set("#f5-shield-book-blue", { fill: "url(#f6-shield-book-blue-grad)" }, "start");
    tl.fromTo("#f6-shield-book-blue-grad-1", { stopColor: "#0e4c87" }, { stopColor: "#27AAE1", duration: 1, ease: "none" }, "start");
    tl.fromTo("#f6-shield-book-blue-grad-2", { stopColor: "#0e4c87" }, { stopColor: "#00BDAA", duration: 1, ease: "none" }, "start");
    tl.from("#f6-blob-left", { morphSVG: '#f6-blob-left-morph', duration: 1, ease: "none" }, "start");
    tl.from("#f6-blob-right", { morphSVG: '#f6-blob-right-morph', duration: 1, ease: "none" }, "start");
    tl.fromTo("#f6-blob-left", { x: -80 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#f6-blob-right", { x: 80 }, { x: 0, duration: 1, ease: "none" }, "start");
    tl.fromTo("#section-02 .heading-column", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "start");
    tl.fromTo("#section-02 .read-more-btn", { opacity: 0 }, { opacity: 1, duration: 0.5, ease: "none" }, "start+=0.5");
    return tl;
}

// master

var t1 = gsap.timeline({
    onStart: function() {
        t1start.seek(t1start.duration());
    },
    scrollTrigger: {
        trigger: "#section-01",
        pin: true,
        start: "top top",
        end: () => "+=" + (section_01.offsetWidth * 5),
        scrub: true,
        /*
        snap: {
            snapTo: [0, 0.06736, 0.2046, 0.38735, 0.58, 0.7907, 0.93, 1],
            duration: { min: 1.6, max: 3.2 },
            delay: 0.4,
        },
        */
        onUpdate: self => {
            // console.log(self.progress.toFixed(5));
            var section_02 = document.querySelector('#section-02');
            if ( section_02.classList.contains("section-active") ) {
                if (self.progress.toFixed(3) < 0.995 && self.direction == -1) {
                    section_02.classList.remove("section-active");
                    var section_01 = document.querySelector("#section-01");
                    if ( section_01.classList.contains("section-fade") ) {
                        section_01.classList.remove("section-fade");
                    }
                    var content_container = section_02.querySelector(".read-more-content-container");
                    content_container.style.height = `0px`;
                    setTimeout(function() {
                        ScrollTrigger.refresh();
                    }, 500);
                }
            }
        }
    }
});
t1.add(t1_a());
t1.add(t1_b());
t1.add(t1_c());
t1.add(t1_d(), "-=2");
t1.add(t1_e());
t1.add(t1_f());