document.addEventListener("DOMContentLoaded",function() {
    var read_mores = document.querySelectorAll('.read-more');
    for (var i = 0; i < read_mores.length; i++) {
        var read_more = read_mores[i];
        read_more.querySelector(".read-more-content-container").style.height = `0px`;
        read_more.querySelector(".read-more-btn a").addEventListener("click", function(e) {
            e.preventDefault();
            var section = e.target.closest('section');
            var this_read_more = e.target.closest('.read-more');
            var this_content_container = this_read_more.querySelector(".read-more-content-container");
            var this_content_height = this_content_container.querySelector(".read-more-content").getBoundingClientRect().height;
            this_content_container.style.height = `${this_content_height}px`;
            if (section && !section.classList.contains("section-active")) {
                section.classList.add("section-activated");
                section.classList.add("section-active");
                if (section.id == 'section-02') { 
                    t2click.pause(0);
                    var section_01 = document.querySelector("#section-01");
                    section_01.classList.add("section-fade");
                }
            }
            setTimeout(function() {
                t2click.play(0);
                this_content_container.style.height = "auto";
                ScrollTrigger.refresh(true);
            }, 500);
        });
    }
    ScrollTrigger.refresh(true);
});

window.addEventListener('resize', function() {
    // ScrollTrigger.refresh();
});
