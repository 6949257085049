var section_09 = document.querySelector('#section-09');

gsap.set("#section-09 .f12-dot-path-pin", { transformOrigin: "center bottom" });

// master

var t9 = gsap.timeline({
    scrollTrigger: {
        id: 't9-in',
        trigger: "#section-09",
        start: "top bottom",
        end: () => "center center",
        scrub: true,
        snap: {
            snapTo: [0.25, 1],
            duration: 1.6,
            delay: 0.4,
        }
    }
});

t9.addLabel("start");
t9.fromTo("#section-09 .intro-title-container", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "start");
t9.fromTo("#f12-group", { x: -1440 }, { x: 0, duration: 1, ease: "none" }, "start");
t9.fromTo(".f12-layer-1", { x: -480 }, { x: 0, duration: 1, ease: "none" }, "start");
t9.fromTo(".f12-layer-2", { x: -960 }, { x: 0, duration: 1, ease: "none" }, "start");
t9.fromTo(".f12-layer-3", { x: -1440 }, { x: 0, duration: 1, ease: "none" }, "start");

var t9b = gsap.timeline({
    scrollTrigger: {
        id: 't9-map',
        trigger: "#section-09",
        pin: true,
        start: "center center",
        end: () => "+=" + (section_09.offsetWidth * 1.5),
        scrub: true,
        snap: {
            snapTo: [0, 1],
            duration: 1.6,
            delay: 0.4,
        }
    }
});

t9b.addLabel("start");
t9b.addLabel("move", "start+=0.5");
t9b.addLabel("content", "move+=0.5");
t9b.addLabel("pause", "content+=0.5");
t9b.fromTo("#f12-dot-path", { drawSVG: "100% 100%" }, { drawSVG: "0% 100%", duration: 1.5, ease: "none" }, "start");
t9b.fromTo("#f12-group", { x: 0, y: 0, }, { x: -1520, y: -1800, duration: 1, ease: "none" }, "move");
t9b.fromTo("#section-09 .intro-title-container", { x: 0, y: 0 }, { x: "-80vw", y: "-93.75vw", duration: 1, ease: "none" }, "move");
t9b.fromTo("#section-09 .intro-title-container", { opacity: 1 }, { opacity: 0, duration: 0.5, ease: "none" }, "content");
t9b.fromTo("#f12-dot-path-pin-1", { scale: 0 }, { scale: 1, duration: 0.1, ease: "back.out(4)" }, "start+=0.025");
t9b.fromTo("#f12-dot-path-pin-2", { scale: 0 }, { scale: 1, duration: 0.1, ease: "back.out(4)" }, "start+=0.35");
t9b.fromTo("#f12-dot-path-pin-3", { scale: 0 }, { scale: 1, duration: 0.1, ease: "back.out(4)" }, "start+=0.75");
t9b.fromTo("#f12-dot-path-pin-4", { scale: 0 }, { scale: 1, duration: 0.1, ease: "back.out(4)" }, "start+=0.9");
t9b.fromTo("#f12-dot-path-pin-5", { scale: 0 }, { scale: 1, duration: 0.1, ease: "back.out(4)" }, "start+=1.4");
t9b.fromTo("#section-09 .content-background-container", { x: "100vw", y: "46.875vw" }, { x: 0, y: 0, duration: 0.5, ease: "none" }, "content");
t9b.fromTo("#section-09 .content-container", { x: "100vw", y: "46.875vw" }, { x: 0, y: 0, duration: 0.5, ease: "none" }, "content");
t9b.fromTo("#section-09 .content-container", { opacity: 0 }, { opacity: 1, duration: 0.5, ease: "none" }, "content");
t9b.add(function(){ /* pause */ }, "pause+=0.5");