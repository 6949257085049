// modal-factoid

document.querySelectorAll('.factoid').forEach(item => {
    item.addEventListener('click', e => {
        e.preventDefault();
        let id = item.dataset.micromodalId;
        MicroModal.show(id, {
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            disableScroll: true,
            openClass: 'modal-open'
        });
    })
});

// modal-stat

document.querySelectorAll('.stat-btn').forEach(item => {
    item.addEventListener('click', e => {
        e.preventDefault();
        MicroModal.show('modal-stat', {
            onShow: function(modal) {
                if (modal.id = 'modal-stat') {
                    modal_stat_content(item);
                }
            },
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            disableScroll: true,
            openClass: 'modal-open'
        });
    })
});

function modal_stat_content(trigger) {
    let content = trigger.dataset.micromodalContent;
    let modal_content = document.getElementById('modal-stat-content');
    if (modal_content) {
        while (modal_content.firstChild) {
            modal_content.removeChild(modal_content.lastChild);
        }
    }
    let xhr = new XMLHttpRequest();
    xhr.open('GET', 'modal/'+ content +'.php');
    xhr.onreadystatechange = function() {
        if (xhr.readyState !== 4) return;
        if (xhr.status === 200) {
            let modal_inner = document.getElementById('modal-stat-inner');
            while (modal_inner.firstChild) {
                modal_inner.removeChild(modal_inner.lastChild);
            }
            modal_inner.innerHTML = xhr.responseText;
            modal_inner.scrollTo(0,0);
            document.querySelectorAll('.modal-sidebar-nav-btn').forEach(item => {
                item.addEventListener('click', e => {
                    e.preventDefault();
                    modal_stat_content(item);
                })
            });
        } else {
            console.log('HTTP error', xhr.status, xhr.statusText);
        }
    };
    xhr.send();
}