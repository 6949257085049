var section_07 = document.querySelector('#section-07');

gsap.set("#section-07 .profile-image-container", { transformOrigin: "center center" });
gsap.set("#section-07 .profile-mask-path", { transformOrigin: "center center" });

// master

var t7 = gsap.timeline({
    scrollTrigger: {
        id: 't7a',
        trigger: "#section-07 .profile-intro-container",
        start: "top bottom",
        end: "center center",
        scrub: true,
        snap: {
            snapTo: [0.25, 1],
            duration: 1.6,
            delay: 0.4,
        }
    }
});

// t7

var h1_split = new SplitText("#section-07 .profile-intro-content h1", {type: "chars,words,lines", position: "relative"});

t7.addLabel("start");
t7.addLabel("morph", "start+=0.5");
t7.addLabel("text", "morph+=0.5");
t7.addLabel("text-p", "text+=0.5");
t7.fromTo("#section-07 .blob-mask-path", { x: 2560 }, { x: -960, duration: 2, ease: "none" }, "start");
t7.from("#section-07 .blob-mask-path", { morphSVG: '#section-07 .blob-mask-morph', duration: 2, ease: "none" }, "morph");
t7.from("#section-07 .blob-1", { morphSVG: '#section-07 .blob-1-morph', duration: 2, ease: "none" }, "morph");
t7.from("#section-07 .blob-3", { morphSVG: '#section-07 .blob-3-morph', duration: 2, ease: "none" }, "morph");
t7.fromTo("#section-07 .blob-2", { x: 1280 }, { x: 0, duration: 2, ease: "none" }, "morph");
t7.fromTo("#section-07 .profile-image-container", { x: "75vw", scale: 0.5 }, { x: 0, scale: 1, duration: 2, ease: "none" }, "morph");
t7.from("#section-07 .profile-mask-path", { morphSVG: '#section-07 .profile-mask-morph', duration: 2, ease: "none" }, "morph");
t7.fromTo("#section-07 .profile-mask-path", { scale: 0.5 }, { scale: 1, duration: 2, ease: "none" }, "morph");
t7.fromTo("#section-07 .profile-line-path", { drawSVG: "0%" }, { drawSVG: "100%", duration: 1, ease: "none" }, "text-p");
t7.fromTo("#section-07 .profile-intro-content h1", { x: 100 }, { x: 0, duration: 1.5, ease: "none" }, "text");
t7.fromTo(h1_split.chars, { opacity: 0 }, { opacity: 1, stagger: 0.1, duration: 1, ease: "none" }, "text");
t7.fromTo("#section-07 .profile-tag-container", { x: -100, opacity: 0 }, { x: 0, opacity: 1, duration: 1, ease: "none" }, "text-p");
t7.fromTo("#section-07 .profile-intro-content p", { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1, ease: "none" }, "text-p");

// t7b

var t7b = gsap.timeline({
    scrollTrigger: {
        id: 't7b',
        trigger: "#section-07 .profile-intro-container",
        pin: true,
        start: "center center",
        end:  "bottom center",
        scrub: true
    }
});

t7b.addLabel("start");
t7b.addLabel("content", "start+=1");
t7b.fromTo("#section-07 .blob-mask-path", { x: -960 }, { x: -3200, duration: 2, ease: "none" }, "start");
t7b.fromTo("#section-07 .blob-1", { x: 0 }, { x: -3200, duration: 2, ease: "none" }, "start");
t7b.fromTo("#section-07 .blob-2", { x: 0, }, { x: -3200, duration: 2, ease: "none" }, "start");
t7b.fromTo("#section-07 .blob-3", { x: 0 }, { x: -3200, duration: 2, ease: "none" }, "start");
t7b.fromTo("#section-07 .profile-background", { y: 0 }, { y: "-50vh", duration: 2, ease: "none" }, "start");
t7b.fromTo("#section-07 .profile-intro", { x: 0, y: 0 }, { x: "-100vw", y: "-50vh", duration: 2, ease: "none" }, "start");
t7b.fromTo("#section-07 .profile-intro", { opacity: 1 }, { opacity: 0, duration: 1, ease: "none" }, "start");
t7b.fromTo("#section-07 .profile-banner", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "content");
t7b.fromTo("#section-07 .profile-banner-image", { y: "-10vh" }, { y: 0, duration: 1, ease: "none" }, "content");
t7b.fromTo("#section-07 .profile-article-container", { opacity: 0 }, { opacity: 1, duration: 1, ease: "none" }, "content");